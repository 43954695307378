#find-artist-page{
    min-height: 90vh;
    background-color: white;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
}

#filters{
    display: flex;
    justify-content: left;
    align-items: center;
    flex-wrap: wrap;
    padding: 1rem;
    min-height: 80px;
}

#filters >*{
    margin: 10px;
}

#artist-list{
    display: flex;
    flex-wrap: wrap;
    width: calc(100vw - 2rem);
    margin: 0 auto 1rem auto;
    position: relative;
    min-height: 70vh;
}

#filter-btn{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 150px;
    height: 30px;
    background-color: #ecbdba;
    border: unset;
    border-radius: 3px;
    color: white;
    font-family: 'Mont', sans-serif;
}

.filter-opt{
    height: 30px;
    border-radius: 3px;
    padding: 0 5px;
    border: 2px solid #ecbdba;
    background-color: unset;
}

#filter-btn:hover{
    cursor: pointer;
}

.skeleton-loader{
    padding: 1rem;
    display: flex;
    flex-direction: column;
    width: 20%;
    position: relative;

}

#categories{
    display: flex;
    overflow: scroll;
    max-width: calc(100vw - 1rem);
    margin: 2rem auto 0 auto;
    z-index: 998;
}

.category-icon{
    margin: 0 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 10px;
    object-fit: contain;
    object-position: center;
}

.category-icon-active{
    margin: 0 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 10px;
    border-bottom: 2px solid grey;
    object-fit: contain;
    object-position: center;
}

.category-icon p{ 
    margin: 10px;
    color: grey;
    font-weight: bold;

}

.category-icon-active p{ 
    margin: 10px;
    color: #313131;
    font-weight: bold;
}

@media only screen and (max-width: 1400px) {
    .skeleton-loader{
        width: 25%;
    }
}

@media only screen and (max-width: 1100px) {
    .skeleton-loader{
        width: 33%;
    }
}

@media only screen and (max-width: 850px) {
    .skeleton-loader{
        width: 50%;
        padding: 10px;
    }
}