#request-list-page{
    min-height: 90vh;
    background-color: #f5f5f5;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-x: auto;
}

#request-list-table{
    max-width: 1300px;
    width: 95vw;
    margin: 0 auto;
    max-width: 95vw;
    border-collapse:collapse;

}

#request-list-table tr:hover{
    cursor: pointer;
}

#request-list-page > *{
    color: #434C5E;
}

#request-list-table th{
    text-align: left;
}

#thead-tr{
    border-bottom: 1px solid #434C5E;
}

#request-list-table td{
    padding: 1rem;
}

#request-list-table tr:nth-child(even) {
    background: #f3dad8;
}

.archived-checkbox-label {
    position: relative;
    cursor: pointer;
}

.archived-checkbox-label .tooltip {
    visibility: hidden;
    width: 120px;
    background-color: #333;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px;
    position: absolute;
    z-index: 1;
    bottom: 100%;
    left: 50%;
    margin-left: -60px;
    opacity: 0;
    transition: opacity 0.3s;
}

.archived-checkbox-label:hover .tooltip {
    visibility: visible;
    opacity: 1;
}

.archived-entry {
    color: #AAA;
}
