#system-report-page{
    min-height: 90vh;
    background-color: #f5f5f5;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-x: auto;
}

#system-report-table{
    max-width: 1300px;
    width: 95vw;
    margin: 0 auto;
    max-width: 95vw;
    border-collapse:collapse;

}

#system-report-table tr:hover{
    cursor: pointer;
}

#system-report-page > *{
    color: #434C5E;
}

#system-report-table th{
    text-align: left;
}

#thead-tr{
    border-bottom: 1px solid #434C5E;
}

#system-report-table td{
    padding: 1rem;
}

#system-report-table tr:nth-child(even) {
    background: #f3dad8;
}
