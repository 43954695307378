#client-nav{
    height: 100px;
    width: 100vw;
    background-color: #1e1e1e;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2rem;
}

.client-nav-link{
    color: #ECEFF4;
    text-decoration: none;
    font-weight: bold;
    margin: 0 1rem;
}

.client-nav-link:hover{
    color: #ecbdba;

}

#logout-btn, #logout-side-nav{
    background-color:unset;
    color: white;
    border-radius: 5px;
    height: 40px;
    width: 150px;
    font-weight: bold;
    border: 2px solid #ecbdba;
}

#logout-btn:hover{
    cursor: pointer;
}

#nav-logo{
    height: 40px;
    margin-top: 15px;
}

.side-nav-closed{
    display: none;
}

.side-nav-open{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    position: absolute;
    height: 100vh;
    width: 100vw;
    background-color: #1e1e1e;
    z-index: 1051; /*Goes above MUI FAB*/
    top: 0;
    left: 0;
    padding: 0 0 20vh 0;
}

#burger-menu{
    display: none;
}

@media only screen and (max-width: 600px) {
    #nav-links, #logout-btn{
        display: none;
    }
    #burger-menu{
        display: block;
    }
  }