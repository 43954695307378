#act-manager-page{
   text-align: center;
   padding: 1rem 0;
}

.act-manager-image{
    width: 20vw;
    height: calc(20vw - 3rem);
    border-radius: 8px;
    max-width: 95vw;
    object-fit: cover;
    object-position: center;
    margin: 1rem;
}

#act-info-container{
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 600px;
    max-width: 95vw;

}

#act-info-container label{
    font-weight: bold;
    margin: 10px 0 5px 0;
}

#set-list{
    display: inline-flex;
    width: 400px;
    max-width: 95vw;
    align-items: left;
    flex-direction: column;
}

.song-item{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.edit-act-input{
    height: 54px;
    width: 450px;
    max-width: 90vw;
    border: 1px solid grey;
    padding-left: 10px;
    font-size: 16px;
    border-radius: 8px;
    flex-shrink: 0;
    background-color: unset;
}

.edit-act-bio{
    height: 100px;
    width: 450px;
    max-width: 90vw;
    border: 1px solid grey;
    padding-left: 10px;
    font-size: 16px;
    border-radius: 8px;
    flex-shrink: 0;
    background-color: unset;
}

#update-act-btn{
    background-color: #ecbdba;
    color: white;
    width: 200px;
    border: unset;
    font-weight: bold;
    height: 50px;
}

#add-song-btn{
    border: 2px solid #ecbdba;
    border-radius: 2px;
    color: #ecbdba;
    font-weight: bold;
    width: 50%;
    margin: 1rem auto;
}

.remove-song-btn{
    border: 1px solid crimson;
    color: crimson;
    background-color: unset;
    border-radius: 5px;
    height: 28px;
    font-size: 10px;
}

@media only screen and (max-width: 1400px) {
  
    .act-manager-image{
        width: 25vw;
        height: calc(25vw - 3rem);

    }

}

@media only screen and (max-width: 1100px) {
  
    .act-manager-image{
        width: 33vw;
        height: calc(33vw - 3rem);
    }
  
}

@media only screen and (max-width: 850px) {
   
    .act-manager-image{
        width: 70vw;
        height: calc(70vw - 3rem);
    }

  

   
}
