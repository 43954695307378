#chat-page{
    min-height: calc(100vh - 50px) ;
    padding: 2rem;
    width: 100vw;
    background-color: #f5f5f5;
}

#msg-box{
    height: 20vh;
    width: 98%;
    max-width: 800px;
    margin: 2rem auto;
    border: unset;
    border-radius: 3px;
    background-color: white;
    color: #1e1e1e;
    font-size: 16px;
    padding: 1rem;
    resize: none;
}

.artist-msg{
    background-color: #ecbdba;
    color: white;
    padding: 1rem;
    width: 600px;
    max-width: 80vw;
    border-radius: 0 10px 10px 10px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    margin: 1rem;
}

.client-msg{
    background-color: #fff;
    padding: 1rem;
    width: 600px;
    max-width: 80vw;
    border-radius: 10px 0 10px 10px ;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    margin: 1rem;
}

.client-msg-container{
    display: flex;
    justify-content: right;
}

.timestamp{
    color: grey;
}

#message-container{
    width:100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1rem;
}

#send-btn{
    background-color: #ecbdba;
    color: white;
    font-weight: bold;
    font-family: 'Mont', sans-serif;
    padding: 1rem;
    border: unset;
    border-radius: 3px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
   

}

#send-btn:hover{
    cursor: pointer;
}