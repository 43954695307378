.card-element-container {
    padding-top: 10px;
    padding-bottom: 20px;
    max-width: 95%;
    margin: 0 auto;

    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
}

/* styles.css */
.card-element {
    margin: 1rem 0;
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f9f9f9;
    margin-right: 10px;
}

.card-element input {
    font-size: 18px;
}

.stripe-attribution img {
    width: 150px;
    margin-top: 10px;
}

.card-element.full-width {
    width: 100%;
}

.card-element.narrow-width {
    width: 33%;
}

.smallParagraph {
    font-size: 14px;
    margin-bottom: 8px;
    margin-top: 4px;
    text-align: center;
}

#payment-btn{
    background-color: #ecbdba;
    border: unset;
    border-radius: 3px;
    color: white;
    font-weight: bold;
}
#payment-btn:hover{
    cursor: pointer;
}
