*{
  box-sizing: border-box;
  font-family: 'Albert Sans', sans-serif;
}

.fdm-btn{
  background-color: #ecbdba;
  border-radius: 3px;
  min-width: 150px;
  color: white;
  font-weight: bold;
  border: unset;
  height: 40px;
  transition: 0.5s;
}

.inverted-btn{
  background-color: white;
  border-radius: 3px;
  min-width: 150px;
  color: #ecbdba;
  font-weight: bold;
  border: 2px solid #ecbdba;
  height: 40px;
  transition: 0.5s;
}

.fdm-btn:hover, .inverted-btn:hover{
  cursor: pointer;
  background-color: #e3a8a4;
  color: white;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

}

button:hover{
  cursor: pointer;
}

.hover:hover{
  cursor: pointer;
}

.vertical-spacer{
  margin: 10px 0;
}

.hover-with-background:hover{
  background-color: #f1f1f1;
  cursor: pointer;
  border-radius: 8px;
  transition: background-color 0.3s;
}
.header-popover{
  background-color: white;
  min-height: 100px;
  width: 200px;
  border-radius: 8px;
  position: absolute;
  top: 50px;
  right: 0;
  z-index: 999;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  display: flex;
  flex-direction: column;
  padding: 5px;
  color: #313131;
}

.popover-item{
  padding: 1rem;
  margin: 0;
  transition: 0.3s;

}

#dropdown-wrapper{
  border: 1px solid #fff;
  padding: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  border-radius: 20px;
}

#burger-container{
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 30px;
  width: 20px;
  margin: 0 10px;
}

.burger-line{
  width: 100%;
  height: 2px;
  background-color: #fff;
}

.divider{
  width: 100%;
  height: 1px;
  background-color: #dfdfdf;
  margin: 5px 0;
}

input[type='date'],
input[type='time']{
  -webkit-appearance: none; /*Used to prevent mobile styling inconsistencies */

}

.primary-btn{
  background-color: #e3a8a4;
  border: unset;
  border-radius: 5px;
  color: white;
  font-family: 'Mont';
  padding: 10px;
}

.green-btn{
  background-color: green;
  border: unset;
  border-radius: 5px;
  color: white;
  font-family: 'Mont';
  padding: 10px;
}

.mobile-menu-item{
  display: none;

}

@font-face {
  font-family: 'Mont';
  src: local('Mont'), url(Fonts/Mont-Heavy.otf) format('opentype');
}

@font-face {
  font-family: 'MontLight';
  src: local('MontLight'), url(Fonts/Mont-light.otf) format('opentype');
}

@media only screen and (max-width: 748px) {
  .mobile-remove{
    display: none !important;
  }

  .mobile-menu-item{
    display: block;
    text-decoration: none;
    color: #313131;
  }
 }