.artist-container{
    padding: 1rem;
    display: flex;
    flex-direction: column;
    width: 20%;
    position: relative;
}

.artist-container h3{
    margin: 0;
}

.artist-container-img{
    width: 100%;
    height: calc(18vw - 3rem);
    object-position: center;
    object-fit: cover;
    border-radius: 12px;
    overflow: hidden;           /*For Youtube border radius*/
}

.play-btn{
    background-color: rgba(17,17,17,0.5);
    border: 1px solid white;
    color: white;
    z-index: 3;
    position: absolute;
    left: 2rem;
    top: 2rem;
    font-weight: bold;
    height: 30px;
    border-radius: 15px;
    padding: 0 10px;
    font-size: 12px;
}

.artist-play-img{
    width: 100%;
    height: calc(18vw - 3rem);
    object-position: center;
    object-fit: cover;
    border-radius: 12px;
}

.artist-container-info{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: left;
    height: 100px;
    padding: 5px;
}

.act-name{
    text-align: left;
    font-family: 'Mont', sans-serif;
}

.view-artist-btn{
    background-color: #ecbdba;
    border: unset;
    border-radius: 3px;
    color: white;
    font-size: 1rem;
    font-weight: bold;
    padding: 0.5rem;
    margin-top: 0;

}
.play-btn:hover, .view-artist-btn:hover{
    cursor: pointer;
}

.artist-container-info h3{
    color: #434C5E;
}

.favourite{
    position: absolute;
    height: 25px;
    width: 25px;
    top: 2rem;
    right: 2rem;
    z-index: 3;
}

@media only screen and (max-width: 1400px) {
    .artist-container{
        width: 25%;
    }
   
    .artist-container-img, .play-btn-container, .artist-play-img{
        height: calc(23vw - 3rem);

    }

}

@media only screen and (max-width: 1100px) {
    .artist-container{
        width: 33%;
    }
    .artist-container-img, .play-btn-container, .artist-play-img{
        height: calc(31vw - 3rem);
    }
  
}

@media only screen and (max-width: 850px) {
    .artist-container{
        width: 50%;
        padding: 10px;
    }
    .artist-container-img, .play-btn-container, .artist-play-img{
        height: calc(48vw - 3rem);
    }

    .act-name{
        font-size: 16px;
    }

   
}

@media only screen and (max-width: 550px) {
    .play-btn{
        top: 1rem;
        left: 1rem;
        height: 25px;
        font-size: 10px;
        
    }

    .favourite{
        top: 1rem;
        right: 1rem;
        height: 20px;
        width: 20px;
    }
}
