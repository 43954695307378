footer{
    background-color: #ecbdba;
    display: flex;
    flex-wrap: wrap;
    min-height: 200px;
    align-items: center;
    justify-content: space-between;
    padding: 2rem;
}

footer > *{
    color: white;
    font-family: 'Mont', sans-serif;
}

.footer-link{
    color: white;
    font-family: 'Mont', sans-serif;
    text-decoration: none;
    margin: 5px 0;
}

.footer-social{
    height: 40px;
    width: 40px;
    object-fit: contain;
    object-position: center;
    margin: 5px;
}