#artist-nav{
    height: 100px;
    width: 100vw;
    background-color: #1e1e1e;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2rem;
}

/* Style for the container of menu items */
#nav-links {
    display: flex;
    align-items: center;
    justify-content: center;
}

.artist-nav-link{
    color: #ECEFF4;
    text-decoration: none;
    font-weight: bold;
    margin: 0 1rem;
    cursor: pointer;
}

.artist-nav-link:hover{
    color: #ecbdba;
}

nav .artist-nav-link.admin {
    font-size: 11.2px;
    font-family: 'Mont', sans-serif;
    letter-spacing: 2px;
    display: flex;
    align-items: center;
}


.burger-artist-nav-link{
    color: #ECEFF4;
    text-decoration: none;
    font-weight: bold;
    margin: 0 1rem;
    cursor: pointer;
    align-items: center;
    font-family: 'Albert Sans', sans-serif;
}

.burger-artist-nav-link.admin {
    font-size: 16px;
    letter-spacing: 1px;
    display: flex;
}


/* Style the expand icon */
.expand-icon {
    font-size: 16px;
    margin-left: 5px; /* Create space between text and icon */
    transition: transform 0.2s ease; /* Add a smooth transition */
}

/* Style the expanded state of the expand icon */
.expand-icon.expanded {
    transform: rotate(180deg); /* Rotate the icon when expanded */
}


 /* Style for the admin menu item */
 .admin-menu {
    color: #ECEFF4;
    font-weight: bold;
    cursor: pointer;
    display: flex;
    align-items: center;
    font-size: 11.2px;
    font-family: 'Mont', sans-serif;
    letter-spacing: 2px;
}

.admin-menu:hover {
    color: #ecbdba;
}

/* admin dropdown menu */
.admin-dropdown {
    display: none;
    position: absolute;
    top: 65px; /* Position it below the ADMIN link */
    background-color: #1e1e1e;
}

.admin-dropdown.active {
    display: block;
}


#logout-btn, #logout-side-nav{
    background-color:unset;
    color: white;
    border-radius: 5px;
    height: 40px;
    width: 150px;
    font-weight: bold;
    border: 2px solid #ecbdba;
}

#logout-btn:hover{
    cursor: pointer;
}

#nav-logo{
    height: 40px;
    margin-top: 15px;
}

#artist-list-table{
    max-width: 1300px;
    width: 95vw;
    overflow-x: auto;

}

#artist-list-table td{
    text-align: left;
}

.side-nav-closed{
    display: none;
}

.side-nav-open{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    position: absolute;
    height: 100vh;
    width: 100vw;
    background-color: #1e1e1e;
    z-index: 1051; /*Goes above MUI FAB*/
    top: 0;
    left: 0;
    padding: 0 0 20vh 0;
}

#burger-menu{
    display: none;
}

@media only screen and (max-width: 600px) {
    #nav-links, #logout-btn{
        display: none;
    }
    #burger-menu{
        display: block;
    }
}
