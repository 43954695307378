.timing-input{
    margin: 1rem 0;
    width: 47.5%;
    height: 60px;
    border-radius: 4px;
    border: 1px solid rgb(180, 180, 180);
    padding: 0 10px;
    font-size: 16px;
    color: #1e1e1e;
}

.timing-input::placeholder{
    color: rgb(110, 110, 110);
}