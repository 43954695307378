#artist-profile-page{
    height: 700px;
    min-height: 90vh;
    background-color: white;
    display: flex;
    flex-direction: row;
    width: 100vw;
    position: relative;
}

#mobile-profile-page{
    display: none;
}

.artist-profile-img{
    width: 300px;
    height: 200px;
    object-fit: cover;
    object-position: center;
}

.pink-header{
    color:#ecbdba;
    font-family: 'Mont', sans-serif;
    margin: 0;
}

#quote-btn{
    background-color: #ecbdba;
    border: unset;
    border-radius: 3px;
    color: white;
    font-weight: bold;
    height: 40px;
    margin: 2rem 0;
}
#quote-btn:hover{
    cursor: pointer;
}

.act-type{
    font-family: 'Mont', sans-serif;
    color: grey;
    font-size: 1rem;
    margin: 5px 0;

}


/*New*/
#artist-profile-right{
    height: 100%;
    width: 70%;
    object-fit: cover;
    object-position: center;
    z-index: 2;
    position: absolute;
    right: 0;

    
}
#artist-profile-left{
    height: 100%;
    width: 50%;
    background: linear-gradient(90deg, rgba(255,255,255,1) 85%, rgba(255,255,255,0));

    z-index: 3;
    position: absolute;
    left: 0;
    display: flex;
    flex-direction: column;
    padding: 2rem;
    
}



#main-img-container{
    height: 50vh;
    min-height: 300px;
    width: 100vw;
    position: relative;
}

#mobile-btn-container{
    display: flex;
    justify-content: space-between;
    padding: 1rem;
    flex-wrap: wrap;
}
#enquire-btn{
    background-color: #ecbdba;
    color: white;
    height: 50px;
    font-family: 'Mont';
    text-transform: uppercase;
    letter-spacing: 1px;
    border-radius: 25px;
    width: 50%;
    border: unset;
}
.other-btns{
    height: 50px;
    width: 50px;
    border-radius: 25px;
    border: unset;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #1e1e1e;
}
.mobile-bio{
    padding: 1rem;
    font-weight: 300;
    letter-spacing: 1px;
    line-height: 20px;
}
#mobile-primary-img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

#white-overlay{
    background: linear-gradient(to bottom, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    z-index: 2;
}

#profile-tabs{
    display: flex;
    overflow: scroll;
}

.profile-tab{
    color:#1e1e1e;;
    font-family: 'Mont';
    margin-right: 25px;
    margin-top: 0;
}
.profile-tab-active{
    color:#ecbdba;;
    font-family: 'Mont';
    margin-right: 25px;
    margin-top: 0;
}

.profile-tab:hover, .profile-tab-active:hover{
    cursor: pointer;
}

@media only screen and (max-width: 700px) {
    #artist-profile-page{
        display: none;
    }
    #mobile-profile-page{
        background-color: white;
        display: block;
        width: 100vw;
        margin: 0 auto;
    }
    
}

/* Updated CSS */

.profile-left{
    width: 50%;
    padding-right: 1rem;
}

.profile-right{
    width: 50%;
    padding-left: 1rem;
    
}

.primary-img{
    width: 100%;
    height: 500px;
    object-fit: cover;
    object-position: center;
    border-radius: 12px;
}

.profile-logo{
    width: 100%;
    height: 500px;
    object-fit: contain;
    object-position: center;
    border-radius: 12px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;}

.profile-container{
    width: 90vw;
    max-width: 1200px;
    margin: 1rem auto;
}

.profile-event{
    width: 100%;
    border-bottom: 1px solid #dfdfdf;
    padding: 1rem 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
}

.class-date-sml{
    height: 80px;
    width: 80px;
    background-color: white;
    border-radius: 8px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    margin-right: 10px;
    flex-shrink: 0;
}

.class-month-sml{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #a28f89;
    border-radius: 8px 8px 0 0;
    padding: 5px;
}

.profile-links{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 0;
}

.profile-bio{
    resize: none;
    width: 100%;
    background-color: unset;
    border: unset;
    font-size: 16px;
    padding: 1rem 0;
    font-weight: 300;
    letter-spacing: 1px;
    line-height: 20px;
}

.teacher-pass{
    background-color: #313131;
    width: 100%;
    height: 300px;
    border-radius: 8px;
    margin: 10px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;
    position: relative;
}

.left-hole{
    position: absolute;
    width: 50px;
    height: 50px;
    background-color: #fafafa;
    top: calc(50% - 25px);
    left: -25px;
    border-radius: 25px;
}

.right-hole{
    position: absolute;
    width: 50px;
    height: 50px;
    background-color: #fafafa;
    top: calc(50% - 25px);
    right: -25px;
    border-radius: 25px;
}

.purchase-pass-btn{
    border: 1px solid white;
    background-color: unset;
    color: white;
    padding: 10px;
    font-size: 12px;
}

.pass-title{
    color: white;
    text-align: center;
}

@media only screen and (max-width: 948px) {

    .profile-left{
        width: 100%;
        padding: 0;
    }

    .profile-right{
        width: 100%;
        padding: 1rem 0;
    }
}

@media only screen and (max-width: 550px) {
    .primary-img{
        height: 350px;
       
    }
    .teacher-pass{ 
        height: 200px;
    }
    .pass-title{
        font-size: 14px;
        margin: 10px;
    }
}