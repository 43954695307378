#booking-list-page{
    min-height: 90vh;
    background-color: #f5f5f5;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-x: auto;
}

#booking-list-table{
    max-width: 1300px;
    width: 95vw;
    margin: 0 auto;
    max-width: 95vw;
    border-collapse:collapse;
}

#booking-list-table tr:hover{
    cursor: pointer;
}

#booking-list-page > *{
    color: #434C5E;
}

#booking-list-table th{
    text-align: left;
}

#thead-tr{
    border-bottom: 1px solid #434C5E;
}

#booking-list-table td{
    padding: 1rem;
}

#booking-list-table tr:nth-child(even) {
    background: #f3dad8;
}

.limited-text {
    max-width: 300px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }